.container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.periods {
    background-color: pink;
}

.prediction {
    background-color: red;
}

// modal resources
.modal {
    padding: 16px;
    border-radius: 5px;
    background-color: pink;
}

.overlay {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(61, 61, 61, 0.274);
}

.dateToggle_container {
    display: flex;
    align-items: center;
    .label {
        margin-right: 6px;
    }
    .toggle {
    }
}

.card {
    .body {
        margin-bottom: 12px;
    }
    .actions {
        display: flex;
        justify-content: flex-end;
    }
}
